<template>
  <container style="max-width: 1000px;">
    <LeadForm @submit="createLeadFromJob" @cancel="$router.go(-1)" ref="leadForm" />

    <v-dialog v-model="loading" width="300" persistent>
        <v-card color="primary" dark>
            <v-card-text>
                {{loadingProgressionText}}
                <v-progress-linear
                    :value="loadingProgressionValue"
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
            </v-card-text>
        </v-card>
    </v-dialog>
  </container>
</template>

<script>
import LeadForm from "@/components/Lead/Form/LeadForm";
import LeadMixin from "@/mixins/LeadMixin";

export default {
  components: {
      LeadForm,
  },

  mixins: [ LeadMixin ],
  computed: {
    lead() {
      return this.$store.getters['lead_form/getLead'];
    }
  },

  created() {
    if (!this.lead) {
      const jobId = this.$route.params.job_id;
      this.$router.push({ name: 'lead_new_import_robot', params: { job_id: jobId } });
    }
  },

  async mounted() {
    this.$refs.leadForm.fill({
        ...this.lead,
        is_insured_participant: true,
        insured: this.lead?.participant_insured?.contact,
        city: this.lead?.risk_location?.city,
        city_id: this.lead?.risk_location?.city?.id,
        city_area: this.lead?.risk_location?.city_area,
        city_area_id: this.lead?.risk_location?.city_area?.id,
        country_area: this.lead?.risk_location?.country_area,
        country_area_id: this.lead?.risk_location?.country_area?.id,
        postal_code: this.lead?.risk_location?.postal_code,
        street: this.lead?.risk_location?.street,
        number: this.lead?.risk_location?.number,
        policy: {
          ...this.lead?.policy,
          insurance_objects: this.lead?.policy?.insurance_objects || [],
          coverages: this.lead?.policy?.coverages || [],
          franchises: this.lead?.policy?.franchises || [],
          limits: this.lead?.policy?.limits || [],
        }
    });
  }
}
</script>

<style>

</style>